<template>
  <div>
    <v-dialog
      scrollable
      persistent
      v-model="show"
      max-width="665px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      content-class="rounded-xl"
    >
      <v-overlay class="justify-center align-center" v-if="loading">
        <v-progress-circular
          :size="100"
          :width="8"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <v-card v-else :outlined="$vuetify.theme.dark" rounded="xl">
        <v-card-title class="d-flex justify-space-between">
          <div>
            <div class="title-name" v-if="type == 'add'">Add Lead</div>
            <div class="title-name" v-if="type == 'edit'">Edit Lead</div>
            <div class="sub-title py-1">Please enter details here</div>
          </div>
          <v-btn icon @click="modalClosed">
            <v-icon size="26">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form class="pt-6" lazy-validation ref="form">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  label="Name"
                  :rules="[rules.required]"
                  height="42px"
                  type="text"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-space-between">
                  <div style="width: 15%">
                    <v-select
                      :menu-props="{
                        bottom: true,
                        offsetY: true,
                      }"
                      v-model="selectedCountry"
                      :items="country_list"
                      item-text="name"
                      return-object
                      height="42px"
                      auto-select-first
                      dense
                      hide-details
                      outlined
                      @input="validate_number"
                      @change="getStateList"
                    >
                      <template v-slot:selection="data">
                        <span class="mr-2">
                          <v-img
                            :src="data.item.flag"
                            height="20"
                            width="25"
                            class="rounded"
                            v-if="data.item.flag"
                          ></v-img>
                          <div v-else>
                            {{ data.item.iso2_code }}
                          </div>
                        </span>
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title class="d-flex align-center">
                            <v-img
                              :src="data.item.flag"
                              height="20"
                              max-width="25"
                              class="rounded mr-2"
                              v-if="data.item.flag"
                            ></v-img>
                            <div v-else class="mr-2">
                              {{ data.item.iso2_code }}
                            </div>
                            {{ data.item.name }}
                            ({{ data.item.calling_code }})
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </div>
                  <div style="width: 83%">
                    <v-text-field
                      v-model="form.mob_no"
                      label="Mobile Number"
                      :rules="[rules.required, isMobileValid]"
                      @input="validate_number"
                      hide-spin-buttons
                      height="42px"
                      type="number"
                      outlined
                      dense
                      @keydown="$rules.blockInvalidChar"
                      :prefix="
                        selectedCountry ? selectedCountry.calling_code : ''
                      "
                    >
                    </v-text-field>
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.email"
                  label="Email"
                  :rules="[rules.email]"
                  height="42px"
                  type="email"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.alternative_email"
                  :rules="[$rules.email]"
                  label="Alternate Email"
                  height="42px"
                  type="email"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="form.state"
                  :items="state_list"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  label="Select State"
                  dense
                >
                  <template v-slot:no-data>
                    <v-btn
                      text
                      class="primary--text px-2"
                      block
                      @click="openAddStateDialog"
                    >
                      <v-icon size="18">mdi-plus</v-icon> Add State
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="form.city"
                  :items="city_list"
                  :disabled="form.state == null"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  label="Select City"
                >
                  <template v-slot:no-data>
                    <v-btn
                      text
                      class="primary--text"
                      block
                      @click="openAddCityDialog"
                    >
                      <v-icon size="18">mdi-plus</v-icon> Add City
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  rows="2"
                  type="text"
                  v-model="form.address"
                  flat
                  outlined
                  dense
                  label="Address"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  clearable
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="form.refer"
                  :items="refer_list"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  label="Referred By"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.user.mobile_display }} |
                        {{ data.item.user.email }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-select
                  clearable
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="form.source"
                  :items="source_list"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  label="Source"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  v-model="form.remark"
                  label="Type remarks here..."
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="submit"
            :loading="btnLoading"
            class="text-capitalize px-8 my-2"
            height="40px"
            color="primary"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <StateAddEditModal @on-submit="onStateAdded" />
    <CityAddEditModal @on-submit="onCityAdded" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import RULES from "@/common/fieldRules";
import _ from "lodash";
import StateAddEditModal from "@/components/admin/StateAddEditModal";
import CityAddEditModal from "@/components/admin/CityAddEditModal";

export default {
  name: "addLeadsModal",
  components: {
    StateAddEditModal,
    CityAddEditModal,
  },
  data() {
    return {
      loading: true,
      rules: RULES,
      btnLoading: false,
      number: null,
      form: {
        name: "",
        mob_no: "",
        email: "",
        alternative_email: "",
        state: null,
        source: null,
        refer: null,
        city: null,
        address: "",
        remark: "",
      },
      mobile_country_code: "",
      check: false,
      selectedCountry: "",
      country_list: [],
      state_list: [],
      city_list: [],
      refer_list: [],
      source_list: [],
      isMobileValid: true,
    };
  },
  computed: {
    ...mapGetters({
      getAddLeadsModal: "clientManagement/getAddLeadsModal",
      getCountryList: "getCountryList",
    }),
    show: {
      get() {
        return this.getAddLeadsModal;
      },
      set() {},
    },
    type() {
      return this.$store.state.clientManagement.addLeadsModal.type;
    },
    client_id() {
      return this.$store.state.clientManagement.addLeadsModal.id;
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },

    "form.state"() {
      this.form.city = null;
      this.getCityList();
    },
  },
  methods: {
    ...mapActions({
      LeadsModal: "clientManagement/LeadsModal",
      showSnackbar: "snackBar/showSnackbar",
      toggleAddEditStateDialog: "stateManagement/toggleDialog",
      toggleAddEditCityDialog: "cityManagement/toggleDialog",
    }),

    validate_number() {
      if (this.form.mob_no && this.selectedCountry)
        this.isMobileValid = this.rules.phone_number_validator(
          this.form.mob_no,
          this.selectedCountry.iso2_code
        );
    },

    async openModal() {
      this.loading = true;

      this.getCountryList.forEach((country) => {
        const _country = { ...country };
        _country.calling_code = country.calling_codes
          ? country.calling_codes[0]
          : null;
        this.country_list.push(_country);
      });

      if (this.type === "edit") {
        await this.getClientDetail();

        if (this.mobile_country_code) {
          this.selectedCountry = _.find(this.country_list, [
            "iso2_code",
            this.mobile_country_code,
          ]);
        } else {
          this.selectedCountry = _.find(this.country_list, ["iso2_code", "IN"]);
        }
      } else {
        this.selectedCountry = _.find(this.country_list, ["iso2_code", "IN"]);
      }

      await this.getStateList();
      await this.getCityList();
      await this.getReferList();
      await this.getSourceList();

      this.loading = false;
    },

    modalClosed() {
      this.LeadsModal({ show: false });
      this.$refs.form.reset();
      this.form.name = "";
      this.form.mob_no = "";
      this.form.email = "";
      this.form.alternative_email = "";
      this.form.state = null;
      this.form.source = null;
      this.form.refer = null;
      this.form.city = null;
      this.address = "";
      this.form.remark = "";
      this.mobile_country_code = "";
    },

    getCityList() {
      const params = {
        apply_pagination: false,
        state_id: this.form.state,
      };

      const successHandler = (res) => {
        this.city_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.master.city.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getStateList() {
      const params = {
        apply_pagination: false,
        country_id: this.selectedCountry.id,
      };

      const successHandler = (res) => {
        this.state_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.master.state.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getReferList() {
      const params = {
        query: "{id, user {id, email, mobile_display}, name}",
      };

      const successHandler = (res) => {
        this.refer_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.client.list, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getSourceList() {
      const successHandler = (res) => {
        this.source_list = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.master.source.list, {
        onSuccess: successHandler,
      });
    },

    getClientDetail() {
      const params = {
        client_id: this.client_id,
      };

      const successHandler = (res) => {
        this.form.name = res.data.data.name;

        const user = res.data.data.user;

        this.form.mob_no = user.mobile;
        this.form.email = user.email;
        this.form.alternative_email = user.alternative_email;

        if (user.mobile_country_code)
          this.mobile_country_code = user.mobile_country_code;
        else this.mobile_country_code = "IN";

        if (res.data.data.source) {
          this.form.source = res.data.data.source.id;
        }

        if (res.data.data.city) {
          this.form.city = res.data.data.city.id;
          if (res.data.data.city.state)
            this.form.state = res.data.data.city.state.id;
        }

        if (res.data.data.referred_by) {
          this.form.refer = res.data.data.referred_by.id;
        }

        this.form.remark = res.data.data.remark;
        this.form.address = res.data.data.address;
      };

      return this.$request(this.$keys.GET, this.$urls.client.detail, {
        params: params,
        onSuccess: successHandler,
      });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;

        const successHandler = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.$emit("submitted", res.data.data.id);
          this.modalClosed();
        };

        const finallyHandler = () => {
          this.btnLoading = false;
        };

        let data = {};
        data["name"] = this.form.name;

        data["email"] = this.form.email;
        data["alternative_email"] = this.form.alternative_email;

        if (this.form.mob_no) {
          const phone = this.$utils.getPhoneNumberInfo(
            this.form.mob_no,
            this.selectedCountry.iso2_code
          );
          data["mobile"] = phone.phone_number;
          data["mobile_code"] = phone.phone_code;
          data["mobile_country_code"] = phone.country_code;
        } else data["mobile"] = this.form.mob_no;

        data["city"] = this.form.city;
        data["referred_by"] = this.form.refer;
        data["source"] = this.form.source;
        data["address"] = this.form.address;
        data["remark"] = this.form.remark;

        if (this.client_id) {
          let params = {
            client_id: this.client_id,
          };

          return this.$request(this.$keys.PUT, this.$urls.client.update, {
            params: params,
            data: data,
            onSuccess: successHandler,
            onFinally: finallyHandler,
          });
        } else {
          return this.$request(this.$keys.POST, this.$urls.client.create, {
            data: data,
            onSuccess: successHandler,
            onFinally: finallyHandler,
          });
        }
      } else {
        this.$nextTick(() => {
          const el = document.getElementsByClassName("v-input error--text");
          if (el)
            el[0].scrollIntoView({
              block: "end",
              behavior: "smooth",
            });
          return;
        });
      }
    },

    openAddStateDialog() {
      this.toggleAddEditStateDialog({
        show: true,
        type: "add",
        country: this.selectedCountry.id,
      });
    },

    onStateAdded(newState) {
      this.state_list.push(newState);
      this.form.state = newState.id;
    },

    openAddCityDialog() {
      this.toggleAddEditCityDialog({
        show: true,
        type: "add",
        state: this.form.state,
      });
    },

    onCityAdded(newCity) {
      this.city_list.push(newCity);
      this.form.city = newCity.id;
    },
  },
};
</script>
<style scoped>
.title-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
.sub-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.01em;
  opacity: 0.6;
}
.v-input__prepend-inner {
  max-height: 1px !important;
  padding: 0px !important;
}
</style>
